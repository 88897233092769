import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import { getOrgClinicDocumentJobs } from 'api/documents';

const initialState = {
    clinicOrgDocumentJobs: {
        error: false,
        loaded: false,
        data: []
    }
};

const slice = createSlice({
    name: 'organizationDocuments',
    initialState,
    reducers: {
        setClinicOrgDocumentJobsError(state) {
            state.clinicOrgDocumentJobs.error = true;
            state.clinicOrgDocumentJobs.loaded = true;
        },
        setClinicOrgDocumentJobs(state, action) {
            state.clinicOrgDocumentJobs.error = false;
            state.clinicOrgDocumentJobs.loaded = true;
            state.clinicOrgDocumentJobs.data = action.payload;
        },
        resetClinicOrgDocumentJobs(state) {
            state.clinicOrgDocumentJobs = initialState.clinicOrgDocumentJobs;
        }
    }
});

export default slice.reducer;

export function resetClinicOrgDocumentJobs() {
    dispatch(slice.actions.resetClinicOrgDocumentJobs());
}

export function getClinicOrgDocumentJobs() {
    return async () => {
        try {
            const response = await getOrgClinicDocumentJobs();
            dispatch(slice.actions.setClinicOrgDocumentJobs(response.data.orgDocumentJobs));
        } catch (error) {
            dispatch(slice.actions.setClinicOrgDocumentJobsError(error));
        }
    };
}
