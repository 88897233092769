import moment from 'moment';

export function formatDateUTCWithoutTime(dateString) {
    const parsedDate = new Date(dateString);
    const dateComponent = `${parsedDate.getFullYear()}-${parsedDate.getMonth() + 1}-${parsedDate.getDate()}`;
    return moment.utc(dateComponent, 'YYYY-MM-DD').toISOString();
}

export function formatAsLocalizedDateIgnoringTZ(dateString) {
    const parsedDate = new Date(dateString);
    const dateComponent = `${parsedDate.getUTCFullYear()}-${parsedDate.getUTCMonth() + 1}-${parsedDate.getUTCDate()}`;
    return moment(dateComponent, 'YYYY-MM-DD').toString();
}

export function formatAsLocalizedDateStringIgnoringTZ(dateString) {
    const parsedDate = new Date(dateString);
    return `${parsedDate.getUTCMonth() + 1}/${parsedDate.getUTCDate()}/${parsedDate.getUTCFullYear()}`;
}
