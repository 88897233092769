import axios from 'utils/axios';

const MAX_LIST_FILTER_SIZE = 255;

export async function documentNewPost(patientId, file, fileName, originalFileName, mimeType) {
    try {
        const splitName = originalFileName?.split('.');
        const originalExtension = splitName?.length > 1 ? splitName?.pop() : null;
        const originalMimeType = mimeType;
        const formData = new FormData();

        formData.append('file', file);
        formData.append('fileName', fileName);
        if (originalExtension) {
            formData.append('ext', originalExtension);
        }
        if (originalMimeType) {
            formData.append('mime', originalMimeType);
        }

        const response = await axios({
            method: 'POST',
            url: `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/documents/new`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getDocumentList(patientId, pageSize, pageNumber, fileNameFilter) {
    try {
        const params = { pageSize, pageNumber };
        if (fileNameFilter) {
            params.q = fileNameFilter.substring(0, MAX_LIST_FILTER_SIZE);
        }
        const response = await axios(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/documents/list`, {
            params
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getDocumentSignedURL(patientId, documentId) {
    try {
        const response = await axios(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/documents/${documentId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getOrgDocumentSignedURL(documentId) {
    try {
        const response = await axios(`/api/org-id/{orgId}/clinic-id/{clinicId}/org-document/${documentId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getDocumentCount(patientId, fileNameFilter) {
    try {
        const params = {};
        if (fileNameFilter) {
            params.q = fileNameFilter.substring(0, MAX_LIST_FILTER_SIZE);
        }
        const response = await axios(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/documents/count`, {
            params
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getOrgClinicDocumentJobs() {
    try {
        const response = await axios.get('/api/org-id/{orgId}/clinic-id/{clinicId}/organization-document-jobs');
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
